import * as events from "@shop25/gtm";

window.gtmEvents = events;

document.addEventListener("DOMContentLoaded", () => {
  const gtmPayloads = window.gtm;
  const names = events.GtmEventName;
  const eventsMap = {
    [names.login]: events.LoginEvent,
    [names.signUp]: events.SignupEvent,
    [names.search]: events.SearchEvent,
    [names.viewItem]: events.ViewItemEvent,
    [names.selectItem]: events.SelectItemEvent,
    [names.beginCheckout]: events.beginCheckout,
    [names.purchase]: events.PurchaseEvent,
    [names.viewCart]: events.ViewCartEvent,
  };

  Object.keys(gtmPayloads).map((eventName) => {
    const hasKey = eventName in eventsMap;
    const GtmEvent = eventsMap[eventName];
    const payload = gtmPayloads[eventName];

    if (!hasKey || !Event || !payload) {
      return;
    }

    new GtmEvent().dispatch(gtmPayloads[eventName]);
  });
});
